import React, { useRef } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import UserProfile from "../UserProfile/userProfile";
import avatarPlaceholder from "../../images/avatar_icon.png";
// import { useMsal } from "@azure/msal-react";
import { fetchUserProfile } from "../../actions/userProfileActions";
import BackNavigator from "../BackNavigator/BackNavigator";
// import HomeIcon from "@material-ui/icons/Home";
import Avatar from "@material-ui/core/Avatar";
import { getInitials } from "../../utils/utils";
import Typography from "@material-ui/core/Typography";
import useNetwork from "../UseNetwork";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(0),
    width: "3.5rem",
    height: "3.5rem",
  },
  hide: {
    display: "none",
  },
  app: {
    height: "100vh",
    width: "100%",
  },
  toolbarContainer: {
    display: "flex",
    width: "100%",
    minHeight: "10rem",
    justifyContent: "space-between",
    marginBottom: "1rem",
    marginTop: "1rem",
  },
  menuContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    paddingBottom: "1rem",
  },
  profileContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  filterContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-end",
  },

  locationRow: {
    fontSize: "1.6rem",
    marginBottom: "1rem",
  },
  dateRow: {
    fontSize: "1.6rem",
  },
  profileIconBox: {
    width: "3.5rem",
    height: "3.5rem",
  },
  profileImg: {
    width: "100%",
    height: "100%",
  },
  appContent: {
    width: "100vw",
    background: "#f3f3f3",
  },
  profileAvatar: {
    color: "#353535",
    backgroundColor: "#dfdfdf",
    border: "1px  #353535",
    cursor: "pointer",
    "@media (max-width: 280px)": {
      right: "10px",
    },
  },
}));

const AppHeader = ({
  children,
  employeeInfo,
  profileinfo,
  userProfileinfo,
  headerContent,
  backNaviagtorHidden,
}) => {
  // const { instance } = useMsal();
  const classes = useStyles();
  const headerRef = useRef(0);
  const employeeId = (employeeInfo.empId || "").padStart(6, "0");
  const employeeAvatarName = employeeInfo.name || "";
  const networkStatus = useNetwork();
  const [openProfile, setOpenProfile] = React.useState(false);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);

  // const handleOnClick = () => {
  //   if (source === "wow-crew") {
  //     window.postMessage("close");
  //     window.opener = null;
  //     window.open('', '_self');
  //     window.close();
  //   }
  // };

  const handleProfileClick = (e) => {
    const userData = {
      username: employeeId,
    };
    profileinfo(userData);
    setOpenProfile(true);
    setTimeout(function Pagecallerfun() {
      window.newrelic.addPageAction("User-Profile", { result: "success" });
    }, 1000);
  };
  const closeProfile = () => {
    if (userProfileinfo?.error?.code === "ERR_UP_INVALID_ACCESS_TOKEN") {
      window.location.reload();
    }

    setOpenProfile(false);
    setOpenSnackbar(false);
  };

  const OnLogoutClick = (e) => {
    e.preventDefault();
    setOpenSnackbar(false);
    if (networkStatus) {
      localStorage.clear();
      sessionStorage.clear();
      caches.keys().then(function (names) {
        for (let name of names) caches.delete(name);
      });
      window.location.href = process.env.REACT_APP_PINGONE_LOGOUT_URL;
    } else {
      setOpenSnackbar(true);
    }
  };

  const appBarHeight = headerRef.current.clientHeight || 0;
  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" ref={headerRef} className={classes.appBar}>
        <Toolbar>
          <div className={classes.toolbarContainer}>
            <div className={classes.menuContainer}>
              <div className={classes.profileIconBox} />
              {/* <div onClick={handleOnClick} className={classes.profileIconBox}>
                {source === "wow-crew" && (
                  <HomeIcon
                    className={classes.profileImg}
                    style={{ padding: "2px" }}
                  />
                )}
              </div> */}
              {!backNaviagtorHidden && (
                <div className={classes.profileIconBox}>
                  <BackNavigator
                    className={classes.profileImg}
                    data-testid="back-navigator"
                  />
                </div>
              )}
            </div>

            {headerContent}

            <div className={classes.profileContainer}>
              <div
                className={classes.profileIconBox}
                onClick={handleProfileClick}
                style={{ justifyContent: "center", alignItems: "center" }}
                data-testid="profile-icon"
              >
                <Avatar
                  className={classes.profileAvatar}
                  alt={avatarPlaceholder}
                >
                  <Typography
                    component="span"
                    style={{
                      fontWeight: "bolder",
                      fontSize: "18px",
                      color: "#353535",
                    }}
                  >
                    {getInitials(employeeAvatarName)}
                  </Typography>
                </Avatar>
              </div>

              <UserProfile
                open={openProfile}
                handleClose={closeProfile}
                signOut={OnLogoutClick}
                onConnectionError={openSnackbar}
                data-testid="user-profile"
              />
            </div>
          </div>
        </Toolbar>
      </AppBar>
      <main className={classes.app}>
        <div
          className={classes.appContent}
          style={{
            marginTop: `${appBarHeight}px`,
            height: `calc(100% - ${appBarHeight}px)`,
            width: "100%",
          }}
        >
          {children}
        </div>
      </main>
    </div>
  );
};

const mapStateToProps = (state) => ({
  employeeInfo: state.auth.employeeInfo,
  userProfileinfo: state.userprofile,
});

const mapDispatchToProps = (dispatch) => ({
  profileinfo: (userData) => dispatch(fetchUserProfile(userData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppHeader);
