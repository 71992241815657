const pingIdConfig = {
    environment_id: process.env.REACT_APP_PINGONE_ENV_ID,
    client_id: process.env.REACT_APP_PINGONE_CLIENT_ID,
    redirect_uri: process.env.REACT_APP_PINGONE_REDIRECT_URL,
    scope: 'openid StoreReporting-Test',
    response_type: 'token',
    usePkce: true,
    grant_type: 'implicit',
    logoutRedirectUri: process.env.REACT_APP_PINGONE_REDIRECT_URL
};

export { pingIdConfig };