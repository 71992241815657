/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import { connect } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import AppHeader from "../AppHeader";
import { useLazyQuery } from "@apollo/client";
import SummaryCard, {
  SummaryDataItem,
  InventoryDataItem,
} from "../SummaryCard";
import ErrorMessage from "../ErrorMessage";
import {
  DATA_FETCH_ERROR,
  NO_CONNECTION_ERROR,
  ONLOAN_INVALID_STOREID_ERROR,
  LABOR,
  SALES,
  SERVICES,
  INVENTORY_CONTROL,
  OMNI_CHANNEL,
} from "../../common/constants";
import { makeStyles } from "@material-ui/core/styles";
import "./styles.scss";
import { useHistory } from "react-router-dom";
import SelectDropdown from "../SelectDropdown";
import {
  GET_DEFAULT_LOCATION,
  GET_LABOR_SUMMARY,
  GET_SALES_SUMMARY,
  GET_INVENTORY,
  GET_INVENTORY_EXPANDABLE_VIEW,
  GET_SERVICES_SUMMARY_DRIVERS_DETAILS_SUMMARY,
  GET_SERVICES_EXPANDED_TOP_LEVEL_DATA,
  GET_OMNI_CHANNEL_SUMMARY,
  GET_OMNI_CHANNEL_EXPANDED_TOP_LEVEL_DATA,
} from "../../graphql/queries.js";
import {
  getDropdownOptions,
  getDate,
  toTitleCase,
  toFixedVal,
  getStoreChecker,
  nullCheck,
} from "../../utils/utils";
import Modal from "../Modal";
import { InventoryModal } from "../Modal";
import useNetwork from "../UseNetwork";

const useStyles = makeStyles((theme) => ({
  filterContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "1rem",
  },
  locationRow: {
    fontSize: "2rem",
    marginBottom: ".5rem",
    height: "25px",
    width: " 271px",
    letterSpacing: "0.01em",
  },
  dateRow: {
    fontSize: "1.6rem",
    color: "#353535",
    width: "19rem",
    height: "3rem",
    marginBottom: "1.5rem",
  },
}));

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    fontSize: "1.6rem",
    color: state.isSelected ? "#FFFFFF" : "#353535",
    padding: 5,
  }),
};

const SalesSummary = ({ employeeInfo = {} }) => {

  let history = useHistory();
  const classes = useStyles();
  const [options, setOptions] = React.useState([]);
  const networkStatus = useNetwork();
  const [selectedDateMatrix, setSelectedDateMatrix] = React.useState(null);
  const [selectedDate, setSelectedDate] = React.useState(null);
  const userEmail = employeeInfo.adUpn;
  const [selectedLocation, setSelectedLocation] = React.useState(
    JSON.parse(sessionStorage.getItem("selectedLocation"))
  );
  let selectedStoreId = localStorage.getItem("storeId");
  const [laborDateMatrix, setLaborDateMatrix] = React.useState(null);
  const activeStorechecker = getStoreChecker(selectedStoreId);

  const [
    getSalesSummary,
    {
      data: { salesSummaryV2: { salesReport = [] } = {} } = {},
      error = {},
      loading,
    },
  ] = useLazyQuery(GET_SALES_SUMMARY, {
    context: {
      headers: {
        Authorization: localStorage.getItem("jwtToken"),
      },
    },
  });

  const [
    getDefaultLocation,
    { error: locationError = {}, loading: locationLoading },
  ] = useLazyQuery(GET_DEFAULT_LOCATION, {
    context: {
      headers: {
        Authorization: localStorage.getItem("jwtToken"),
      },
    },

    onCompleted: (data) => {
      let { salesSummaryV2 } = data;
      if (selectedLocation.locationName === "NA") {
        setSelectedLocation({
          locationNo: salesSummaryV2?.location?.primaryLocationNumber,
          locationName: salesSummaryV2?.location?.primaryLocationName
        });
        sessionStorage.setItem(
          "selectedLocation",
          JSON.stringify({
            locationNo: salesSummaryV2?.location?.primaryLocationNumber,
            locationName: salesSummaryV2?.location?.primaryLocationName
          })
        );
      }
    },
  });

  const [
    getServicesSummary,
    {
      data: { services: { services = [] } = {} } = {},
      error: services_error = {},
      loading: services_loading,
    },
  ] = useLazyQuery(GET_SERVICES_SUMMARY_DRIVERS_DETAILS_SUMMARY, {
    context: {
      headers: {
        Authorization: localStorage.getItem("jwtToken"),
      },
    },
  });

  const [
    getLaborSummary,
    {
      data: { labor: { labor = [] } = {} } = {},
      error: laborError = {},
      loading: laborLoading,
    },
  ] = useLazyQuery(GET_LABOR_SUMMARY, {
    context: {
      headers: {
        Authorization: localStorage.getItem("jwtToken"),
      },
    },
  });

  const [
    getInventory,
    {
      data: { inventoryControl: { inventoryControl = [] } = {} } = {},
      error: inventoryerror = {},
      loading: inventoryloading,
    },
  ] = useLazyQuery(GET_INVENTORY, {
    context: {
      headers: {
        Authorization: localStorage.getItem("jwtToken"),
      },
    },
  });

  useEffect(() => {
    let options = [];
    options = getDropdownOptions();
    setOptions(options);
    let defaultValue = options.filter((obj) => {
      return (
        obj.value ===
        (localStorage.hasOwnProperty("selectedDate")
          ? localStorage.getItem("selectedDate")
          : "YESTERDAY")
      );
    });
    setSelectedDate(defaultValue[0]);
    setSelectedDateMatrix(defaultValue[0].value);
  }, [setOptions, setSelectedDate]);

  useEffect(() => {
    if (userEmail && (activeStorechecker === true || selectedStoreId === "")) {
      getDefaultLocation({
        variables: { request: { email: userEmail, storeId: selectedStoreId } },
      });
    }
  }, [userEmail, getDefaultLocation, selectedStoreId, activeStorechecker]);

  useEffect(() => {
    if (
      userEmail &&
      selectedDateMatrix &&
      selectedLocation &&
      selectedLocation?.locationName !== "NA"
    ) {
      getServicesSummary({
        variables: {
          request: {
            email: userEmail,
            location: {
              primaryLocationNumber: selectedLocation?.locationNo,
              primaryLocationName: selectedLocation?.locationName,
            },
            toDate: getDate("YESTERDAY", ""),
            matrixType:
              selectedDateMatrix === "YESTERDAY" ? "DAY" : selectedDateMatrix,
          },
        },
      });
    }
  }, [userEmail, selectedDateMatrix, selectedLocation, getServicesSummary]);
  useEffect(() => {
    if (
      userEmail &&
      selectedDateMatrix &&
      selectedLocation &&
      selectedLocation?.locationName !== "NA"
    ) {
      getSalesSummary({
        variables: {
          request: {
            email: userEmail,
            location: {
              primaryLocationNumber: selectedLocation?.locationNo,
              primaryLocationName: selectedLocation?.locationName,
            },
            toDate: getDate("YESTERDAY", ""),
            matrixType: selectedDateMatrix,
          },
        },
      });
    }
  }, [userEmail, selectedDateMatrix, selectedLocation, getSalesSummary]);

  useEffect(() => {
    if (
      userEmail &&
      selectedDateMatrix &&
      selectedLocation &&
      selectedLocation.locationName !== "NA"
    ) {
      let laborMatrix = "LCW";
      if (selectedDateMatrix === "YESTERDAY" || selectedDateMatrix === "WTD") {
        laborMatrix = "LCW";
      } else {
        laborMatrix = selectedDateMatrix;
      }
      setLaborDateMatrix(laborMatrix);
      getLaborSummary({
        variables: {
          request: {
            email: userEmail,
            toDate: getDate("YESTERDAY", ""),
            location: {
              primaryLocationNumber: selectedLocation?.locationNo,
              primaryLocationName: selectedLocation?.locationName,
            },
            matrixType: laborMatrix,
          },
        },
      });
    }
  }, [userEmail, selectedLocation, selectedDateMatrix, getLaborSummary]);

  useEffect(() => {
    if (
      userEmail &&
      selectedDateMatrix &&
      selectedLocation &&
      selectedLocation?.locationName !== "NA"
    ) {
      getInventory({
        variables: {
          request: {
            email: userEmail,
            location: {
              primaryLocationNumber: selectedLocation?.locationNo,
              primaryLocationName: selectedLocation?.locationName,
            },
            toDate: getDate("YESTERDAY", ""),
            matrixType: selectedDateMatrix,
          },
        },
      });
    }
  }, [userEmail, selectedDateMatrix, selectedLocation, getInventory]);

  const {
    earPiercing = {
      totalSalesUnits: 0,
      totalSalesAmount: 0,
      serviceUnits: 0,
    },
    balloon = {
      totalSalesUnits: 0,
      totalSalesAmount: 0,
      serviceUnits: 0,
    },
  } = services || {};

  const {
    laborDetails = {
      primaryActualPayrollHrs: 0,
      primaryPayrollPlan: 0,
      primaryOvertimePercentage: 0,
      primaryEfficiency: 0,
      secondaryActualPayrollHrs: 0,
      secondaryPayrollPlan: 0,
      secondaryEfficiency: 0,
      secondaryOvertimePercentage: 0,
    },
  } = labor || {};

  const {
    extNetDsctAmt = 0,
    plannedAmt = 0,
    performanceToPlan = 0,
    compPercent = 0,
  } = salesReport[0] || {};

  const handleSalesCardClick = (e) => {
    history.push({
      pathname: "/sales-details/drivers",
    });
  };

  const handleLaborCardClick = (e) => {
    history.push({
      pathname: "/labor-details/labor",
    });
  };

  const handleServicesCardClick = (e) => {
    history.push({
      pathname: "/services-details/drivers",
    });
  };

  const handleOmniChannelCardClick = (e) => {
    history.push({
      pathname: "/omnichannel-details/bopis",
    });
  };

  const handleInventoryControlCardClick = (e) => {
    history.push({
      pathname: "/inventory-details/drivers",
    });
  };

  const handleDateChange = (e) => {
    setSelectedDateMatrix(e.value);
    setSelectedDate(e);
    localStorage.setItem("selectedDate", e.value);
  };

  const [
    getSalesSummaryMatrixData,
    {
      data: dataSalesMatrix = {},
      // eslint-disable-next-line no-unused-vars
      error: salesError = {},
      // eslint-disable-next-line no-unused-vars
      loading: salesLoading,
    },
  ] = useLazyQuery(GET_SALES_SUMMARY, {
    context: {
      headers: {
        Authorization: localStorage.getItem("jwtToken"),
      },
    },
  });

  useEffect(() => {
    if (
      userEmail &&
      selectedDateMatrix &&
      selectedLocation &&
      selectedLocation?.locationName !== "NA"
    ) {
      getSalesSummaryMatrixData({
        variables: {
          request: {
            email: userEmail,
            location: {
              primaryLocationNumber: selectedLocation?.locationNo,
              primaryLocationName: selectedLocation?.locationName,
            },
            toDate: getDate("YESTERDAY", ""),
            matrixType: selectedDateMatrix,
          },
        },
      });
    }
  }, [
    userEmail,
    selectedDateMatrix,
    selectedLocation,
    getSalesSummaryMatrixData,
  ]);

  const salesMatrixData = dataSalesMatrix?.salesSummaryV2?.salesMatrix;

  const [
    getInventoryExpandedData,
    {
      data: {
        inventoryControl: {
          inventoryControlExpandableView: {
            title = "",
            chain,
            regionList = [],
          } = {},
        } = {},
      } = {},
      expandedInventoryerror,
      expandedInventoryloading,
    },
  ] = useLazyQuery(GET_INVENTORY_EXPANDABLE_VIEW, {
    context: {
      headers: {
        Authorization: localStorage.getItem("jwtToken"),
      },
    },
  });

  useEffect(() => {
    if (
      userEmail &&
      selectedDateMatrix &&
      selectedLocation &&
      selectedLocation?.locationName !== "NA"
    ) {
      getInventoryExpandedData({
        variables: {
          request: {
            email: userEmail,
            location: {
              primaryLocationNumber: selectedLocation?.locationNo,
              primaryLocationName: selectedLocation?.locationName,
            },
            toDate: getDate("YESTERDAY", ""),
            matrixType: selectedDateMatrix,
          },
        },
      });
    }
  }, [
    userEmail,
    selectedDateMatrix,
    selectedLocation,
    getInventoryExpandedData,
  ]);

  const [
    getExpandedServicesTopLevelData,
    {
      data: {
        services: {
          servicesExpandableView: {
            title: serviceExpandedTitle = "",
            chain: serviceExpandedChain,
            regionList: serviceExpandedRegionList = [],
          } = {},
        } = {},
      } = {},
      error: expandedServiceTopLevelError,
      loading: expandedServiceTopLevelLoading,
    },
  ] = useLazyQuery(GET_SERVICES_EXPANDED_TOP_LEVEL_DATA, {
    context: {
      headers: {
        Authorization: localStorage.getItem("jwtToken"),
      },
    },
  });

  useEffect(() => {
    if (
      userEmail &&
      selectedDateMatrix &&
      selectedLocation &&
      selectedLocation?.locationName !== "NA"
    ) {
      getExpandedServicesTopLevelData({
        variables: {
          request: {
            email: userEmail,
            location: {
              primaryLocationNumber: selectedLocation?.locationNo,
              primaryLocationName: selectedLocation?.locationName,
            },
            toDate: getDate("YESTERDAY", ""),
            matrixType:
              selectedDateMatrix === `YESTERDAY` ? `DAY` : selectedDateMatrix,
          },
        },
      });
    }
  }, [
    userEmail,
    selectedDateMatrix,
    selectedLocation,
    getExpandedServicesTopLevelData,
  ]);

  const [
    getOmniChannelSummaryData,
    {
      data: { omni: { omni: { bopis, sfs } = {} } = {} } = {},
      error: omniChannelLandingError,
      loading: omniChannelLandingLoading,
    },
  ] = useLazyQuery(GET_OMNI_CHANNEL_SUMMARY, {
    context: {
      headers: {
        Authorization: localStorage.getItem("jwtToken"),
      },
    },
  });

  useEffect(() => {
    if (
      userEmail &&
      selectedDateMatrix &&
      selectedLocation &&
      selectedLocation?.locationName !== "NA"
    ) {
      getOmniChannelSummaryData({
        variables: {
          request: {
            email: userEmail,
            location: {
              primaryLocationNumber: selectedLocation?.locationNo,
              primaryLocationName: selectedLocation?.locationName,
            },
            toDate: getDate("YESTERDAY", ""),
            matrixType:
              selectedDateMatrix === `YESTERDAY` ? `DAY` : selectedDateMatrix,
          },
        },
      });
    }
  }, [
    userEmail,
    selectedDateMatrix,
    selectedLocation,
    getOmniChannelSummaryData,
  ]);

  const [
    getExpandedOmniChannelTopLevelData,
    {
      data: {
        omni: {
          omniExpandableView: {
            title: omniChannelExpandedTitle = "",
            chain: omniChannelExpandedChain,
            regionList: omniChannelExpandedRegionList = [],
          } = {},
        } = {},
      } = {},
      error: expandedOmniChannelTopLevelError,
      loading: expandedOmniChannelTopLevelLoading,
    },
  ] = useLazyQuery(GET_OMNI_CHANNEL_EXPANDED_TOP_LEVEL_DATA, {
    context: {
      headers: {
        Authorization: localStorage.getItem("jwtToken"),
      },
    },
  });

  useEffect(() => {
    if (
      userEmail &&
      selectedDateMatrix &&
      selectedLocation &&
      selectedLocation?.locationName !== "NA"
    ) {
      getExpandedOmniChannelTopLevelData({
        variables: {
          request: {
            email: userEmail,
            location: {
              primaryLocationNumber: selectedLocation?.locationNo,
              primaryLocationName: selectedLocation?.locationName,
            },
            toDate: getDate("YESTERDAY", ""),
            matrixType:
              selectedDateMatrix === `YESTERDAY` ? `DAY` : selectedDateMatrix,
          },
        },
      });
    }
  }, [
    userEmail,
    selectedDateMatrix,
    selectedLocation,
    getExpandedOmniChannelTopLevelData,
  ]);

  const modalLaborSummaryContent = (
    <>
      <SummaryDataItem
        itemAmount={Math.round(
          laborDetails?.secondaryActualPayrollHrs
        ).toLocaleString("en")}
        itemTitle="Payroll Hrs"
      />
      <SummaryDataItem
        itemAmount={Math.round(
          laborDetails?.secondaryPayrollPlan
        ).toLocaleString("en")}
        itemTitle="Payroll Plan"
      />
      <SummaryDataItem
        itemAmount={toFixedVal(
          laborDetails?.secondaryEfficiency,
          "%",
          "end",
          2
        )}
        itemTitle="Efficiency"
      />
      <SummaryDataItem
        itemAmount={toFixedVal(
          laborDetails?.secondaryOvertimePercentage,
          "%",
          "end",
          2
        )}
        itemTitle="OT%"
      />
    </>
  );

  const modalSalesSummaryContent = (
    <>
      <SummaryDataItem
        itemAmount={Math.round(
          nullCheck(salesMatrixData?.sales?.secondary)
        ).toLocaleString("en")}
        itemTitle="Sales $"
      />
      <SummaryDataItem
        itemAmount={toFixedVal(
          salesMatrixData?.sales?.secondaryVsPlan,
          "%",
          "end",
          1
        )}
        itemTitle="% to Plan"
      />
      <SummaryDataItem
        itemAmount={toFixedVal(
          salesMatrixData?.sales?.secondaryCompPercentage,
          "%",
          "end",
          1
        )}
        itemTitle="Comp %"
      />
    </>
  );

  const modalServiceSummaryContent =
    !expandedServiceTopLevelLoading &&
    (!expandedServiceTopLevelError ? (
      <>
        <SummaryDataItem
          itemAmount={
            serviceExpandedTitle === `Chain Summary`
              ? `$` +
              Math.round(
                parseFloat(serviceExpandedChain?.balloonSalesAmount ?? 0)
              ).toLocaleString("en")
              : serviceExpandedTitle === `Region Summary`
                ? `$` +
                Math.round(
                  parseFloat(
                    serviceExpandedRegionList[0]?.balloonSalesAmount ?? 0
                  )
                ).toLocaleString("en")
                : `$` +
                Math.round(
                  parseFloat(
                    serviceExpandedRegionList[0]?.districtList[0]
                      ?.balloonSalesAmount ?? 0
                  )
                ).toLocaleString("en")
          }
          itemTitle={"Ballon\nSales $"}
        />
        <SummaryDataItem
          itemAmount={
            serviceExpandedTitle === `Chain Summary`
              ? Math.round(
                parseFloat(serviceExpandedChain?.balloonTotalSalesUnits ?? 0)
              ).toLocaleString("en")
              : serviceExpandedTitle === `Region Summary`
                ? Math.round(
                  parseFloat(
                    serviceExpandedRegionList[0]?.balloonTotalSalesUnits ?? 0
                  )
                ).toLocaleString("en")
                : Math.round(
                  parseFloat(
                    serviceExpandedRegionList[0]?.districtList[0]
                      ?.balloonTotalSalesUnits ?? 0
                  )
                ).toLocaleString("en")
          }
          itemTitle={"Balloon\nSales U"}
        />
        <SummaryDataItem
          itemAmount={
            serviceExpandedTitle === `Chain Summary`
              ? `$` +
              Math.round(
                parseFloat(serviceExpandedChain?.earPiercingSalesAmount ?? 0)
              ).toLocaleString("en")
              : serviceExpandedTitle === `Region Summary`
                ? `$` +
                Math.round(
                  parseFloat(
                    serviceExpandedRegionList[0]?.earPiercingSalesAmount ?? 0
                  )
                ).toLocaleString("en")
                : `$` +
                Math.round(
                  parseFloat(
                    serviceExpandedRegionList[0]?.districtList[0]
                      ?.earPiercingSalesAmount ?? 0
                  )
                ).toLocaleString("en")
          }
          itemTitle={"Ear Piercing\nSales $"}
        />
        <SummaryDataItem
          itemAmount={
            serviceExpandedTitle === `Chain Summary`
              ? Math.round(
                parseFloat(
                  serviceExpandedChain?.earPiercingServicesUnits ?? 0
                )
              ).toLocaleString("en")
              : serviceExpandedTitle === `Region Summary`
                ? Math.round(
                  parseFloat(
                    serviceExpandedRegionList[0]?.earPiercingServicesUnits ?? 0
                  )
                ).toLocaleString("en")
                : Math.round(
                  parseFloat(
                    serviceExpandedRegionList[0]?.districtList[0]
                      ?.earPiercingServicesUnits ?? 0
                  )
                ).toLocaleString("en")
          }
          itemTitle="Piercings"
        />
      </>
    ) : (
      <>
        <SummaryDataItem itemAmount={0} itemTitle={"Ballon\nSales $"} />
        <SummaryDataItem itemAmount={0} itemTitle={"Balloon\nSales U"} />
        <SummaryDataItem itemAmount={0} itemTitle={"Ear Piercing\nSales $"} />
        <SummaryDataItem itemAmount={0} itemTitle={"Piercings"} />
      </>
    ));

  const modalOmniChannelSummaryContent =
    !expandedOmniChannelTopLevelLoading &&
    (!expandedOmniChannelTopLevelError ? (
      <>
        <SummaryDataItem
          itemAmount={
            omniChannelExpandedTitle === `Chain Summary`
              ? Math.round(
                omniChannelExpandedChain?.bopisFulfilledOrdersCount ?? 0
              )
                .toLocaleString("en")
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              : omniChannelExpandedTitle === `Region Summary`
                ? Math.round(
                  omniChannelExpandedRegionList[0]?.bopisFulfilledOrdersCount ??
                  0
                )
                  .toLocaleString("en")
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                : Math.round(
                  omniChannelExpandedRegionList[0]?.districtList[0]
                    ?.bopisFulfilledOrdersCount ?? 0
                )
                  .toLocaleString("en")
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          }
          itemTitle={"BOPIS Orders \nFulfilled"}
        />
        <SummaryDataItem
          itemAmount={
            omniChannelExpandedTitle === `Chain Summary`
              ? Math.round(
                omniChannelExpandedChain?.bopisUnitsFillRate ?? 0
              ).toLocaleString("en") + "%"
              : omniChannelExpandedTitle === `Region Summary`
                ? Math.round(
                  omniChannelExpandedRegionList[0]?.bopisUnitsFillRate ?? 0
                ).toLocaleString("en") + "%"
                : Math.round(
                  omniChannelExpandedRegionList[0]?.districtList[0]
                    ?.bopisUnitsFillRate ?? 0
                ).toLocaleString("en") + "%"
          }
          itemTitle={"BOPIS Fill \nRate"}
        />
        <SummaryDataItem
          itemAmount={
            omniChannelExpandedTitle === `Chain Summary`
              ? Math.round(
                omniChannelExpandedChain?.sfsFulfilledOrdersCount ?? 0
              )
                .toLocaleString("en")
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              : omniChannelExpandedTitle === `Region Summary`
                ? Math.round(
                  omniChannelExpandedRegionList[0]?.sfsFulfilledOrdersCount ?? 0
                )
                  .toLocaleString("en")
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                : Math.round(
                  omniChannelExpandedRegionList[0]?.districtList[0]
                    ?.sfsFulfilledOrdersCount ?? 0
                )
                  .toLocaleString("en")
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          }
          itemTitle={"SFS Orders \nFulfilled"}
        />
        <SummaryDataItem
          itemAmount={
            omniChannelExpandedTitle === `Chain Summary`
              ? Math.round(
                omniChannelExpandedChain?.sfsUnitsFillRate ?? 0
              ).toLocaleString("en") + "%"
              : omniChannelExpandedTitle === `Region Summary`
                ? Math.round(
                  omniChannelExpandedRegionList[0]?.sfsUnitsFillRate ?? 0
                ).toLocaleString("en") + "%"
                : Math.round(
                  omniChannelExpandedRegionList[0]?.districtList[0]
                    ?.sfsUnitsFillRate ?? 0
                ).toLocaleString("en") + "%"
          }
          itemTitle="SFS Fill Rate"
        />
      </>
    ) : (
      <>
        <SummaryDataItem
          itemAmount={0}
          itemTitle={"BOPIS Orders \nFulfilled"}
        />
        <SummaryDataItem itemAmount={0} itemTitle={"BOPIS Fill \nRate"} />
        <SummaryDataItem itemAmount={0} itemTitle={"SFS Orders \nFulfilled"} />
        <SummaryDataItem itemAmount={0} itemTitle={"SFS Fill Rate"} />
      </>
    ));

  const modalInventorySummaryContent = !expandedInventoryloading &&
    !expandedInventoryerror && (
      <>
        <InventoryDataItem
          itemAmount={
            title === `Chain Summary`
              ? Math.round(
                parseInt(chain?.replaceUnitQuantity ?? 0)
              ).toLocaleString("en")
              : title === `Region Summary`
                ? Math.round(
                  parseInt(regionList[0]?.replaceUnitQuantity ?? 0)
                ).toLocaleString("en")
                : Math.round(
                  parseInt(
                    regionList[0]?.districtList[0]?.replaceUnitQuantity ?? 0
                  )
                ).toLocaleString("en")
          }
          itemTitle="Replace"
        />
        <InventoryDataItem
          itemAmount={
            title === `Chain Summary`
              ? Math.round(
                parseInt(chain?.damagesUnitQuantity ?? 0)
              ).toLocaleString("en")
              : title === `Region Summary`
                ? Math.round(
                  parseInt(regionList[0]?.damagesUnitQuantity ?? 0)
                ).toLocaleString("en")
                : Math.round(
                  parseInt(
                    regionList[0]?.districtList[0]?.damagesUnitQuantity ?? 0
                  )
                ).toLocaleString("en")
          }
          itemTitle="Damages"
        />
        <InventoryDataItem
          itemAmount={
            title === `Chain Summary`
              ? Math.round(
                parseInt(chain?.mosUnitQuantity ?? 0)
              ).toLocaleString("en")
              : title === `Region Summary`
                ? Math.round(
                  parseInt(regionList[0]?.mosUnitQuantity ?? 0)
                ).toLocaleString("en")
                : Math.round(
                  parseInt(regionList[0]?.districtList[0]?.mosUnitQuantity ?? 0)
                ).toLocaleString("en")
          }
          itemTitle="MOS"
        />
        <InventoryDataItem
          itemAmount={
            title === `Chain Summary`
              ? Math.round(
                parseInt(chain?.knownTheftUnitQuantity ?? 0)
              ).toLocaleString("en")
              : title === `Region Summary`
                ? Math.round(
                  parseInt(regionList[0]?.knownTheftUnitQuantity ?? 0)
                ).toLocaleString("en")
                : Math.round(
                  parseInt(
                    regionList[0]?.districtList[0]?.knownTheftUnitQuantity ?? 0
                  )
                ).toLocaleString("en")
          }
          itemTitle="Known Theft"
        />

        <InventoryDataItem
          itemAmount={
            title === `Chain Summary`
              ? Math.round(
                parseInt(chain?.shipmentUnitQuantity ?? 0)
              ).toLocaleString("en")
              : title === `Region Summary`
                ? Math.round(
                  parseInt(regionList[0]?.shipmentUnitQuantity ?? 0)
                ).toLocaleString("en")
                : Math.round(
                  parseInt(
                    regionList[0]?.districtList[0]?.shipmentUnitQuantity ?? 0
                  )
                ).toLocaleString("en")
          }
          itemTitle="Shipment"
        />
        <InventoryDataItem
          itemAmount={
            title === `Chain Summary`
              ? Math.round(
                parseInt(chain?.candyUnitQuantity ?? 0)
              ).toLocaleString("en")
              : title === `Region Summary`
                ? Math.round(
                  parseInt(regionList[0]?.candyUnitQuantity ?? 0)
                ).toLocaleString("en")
                : Math.round(
                  parseInt(
                    regionList[0]?.districtList[0]?.candyUnitQuantity ?? 0
                  )
                ).toLocaleString("en")
          }
          itemTitle="Candy"
        />
      </>
    );

  useEffect(() => {
    setTimeout(function Pagecallerfun() {
      window.newrelic.addPageAction("Sales-Summary", { result: "success" });
    }, 1000);
  }, []);

  const primarylocation = selectedLocation?.locationName;
  const statelocation = primarylocation.split(/\s*[\s,]\s*/).pop();
  let withoutstatelocation = "";
  primarylocation !== "NA"
    ? statelocation.length === 2
      ? (withoutstatelocation = primarylocation?.slice(
        0,
        primarylocation.lastIndexOf(",")
      ))
      : (withoutstatelocation = primarylocation)
    : (withoutstatelocation = "NA");

  return (
    <>
      <AppHeader
        headerContent={
          <div className={classes.filterContainer}>
            {locationLoading ? (
              <CircularProgress color="inherit" size={30} />
            ) : (
              <div className="locationRow">
                {toTitleCase(withoutstatelocation ?? withoutstatelocation)}
                {primarylocation !== "NA" && statelocation.length === 2 ? (
                  <span>, {statelocation}</span>
                ) : (
                  ""
                )}
              </div>
            )}
            {options.length > 0 ? (
              <div className="blue-dropdown">
                <SelectDropdown
                  className={classes.dateRow}
                  styles={customStyles}
                  onChange={handleDateChange}
                  options={options}
                  value={selectedDate}
                  isSearchable={false}
                />
              </div>
            ) : (
              <CircularProgress color="inherit" size={30} />
            )}
          </div>
        }
        backNaviagtorHidden={true}
      >
        {activeStorechecker === false && selectedStoreId ? (
          <ErrorMessage message={ONLOAN_INVALID_STOREID_ERROR} />
        ) : null}
        {error.message ||
          locationError.message ||
          services_error.message ||
          laborError.message ||
          inventoryerror.message ||
          omniChannelLandingError?.message ? (
          <ErrorMessage
            statusCode={
              error?.networkError?.statusCode ||
              locationError?.networkError?.statusCode ||
              services_error?.networkError?.statusCode ||
              laborError?.networkError?.statusCode ||
              inventoryerror?.networkError?.statusCode ||
              omniChannelLandingError?.networkError?.statusCode
            }
            message={
              error?.networkError?.statusCode ||
                locationError?.networkError?.statusCode ||
                services_error?.networkError?.statusCode ||
                laborError?.networkError?.statusCode ||
                inventoryerror?.networkError?.statusCode ||
                omniChannelLandingError?.networkError?.statusCode
                ? DATA_FETCH_ERROR
                : laborError.message
            }
          />
        ) : null}

        {!networkStatus && <ErrorMessage message={NO_CONNECTION_ERROR} />}
        <div className="sales-summary">
          <div className="SummaryCard-container">
            <Modal
              SalesSummaryCard="true"
              title={" Sales Summary"}
              titleInModal={salesMatrixData?.secondTitle}
              ExpandedSalesSummaryComponent="true"
              primaryLocationNumber={selectedLocation?.locationNo}
              primaryLocationName={selectedLocation?.locationName}
              selectedDateMatrix={selectedDateMatrix}
              modalContentOther={modalSalesSummaryContent}
            />
            <div>
              <SummaryCard
                title={SALES}
                handleSalesCardClick={handleSalesCardClick}
                primaryLocationName={selectedLocation?.locationName}
                selectedDateMatrix={selectedDateMatrix}
              >
                {loading && (
                  <div className="data-row">
                    <CircularProgress color="primary" />
                  </div>
                )}
                {!loading && (
                  <>
                    <div className="data-row">
                      <SummaryDataItem
                        itemAmount={Math.round(extNetDsctAmt).toLocaleString(
                          "en"
                        )}
                        itemTitle="Sales $"
                      />
                      <SummaryDataItem
                        itemAmount={Math.round(plannedAmt).toLocaleString("en")}
                        itemTitle="Plan $"
                      />
                    </div>
                    <div className="data-row">
                      <SummaryDataItem
                        itemAmount={toFixedVal(
                          performanceToPlan,
                          "%",
                          "end",
                          1
                        )}
                        itemTitle="% to Plan"
                      />
                      <SummaryDataItem
                        itemAmount={toFixedVal(compPercent, "%", "end", 1)}
                        itemTitle="Comp"
                      />
                    </div>
                  </>
                )}
              </SummaryCard>
            </div>
          </div>
          <div className="ServicesCard-container">
            <Modal
              title={`${SERVICES} Summary`}
              titleInModal={
                serviceExpandedTitle ? salesMatrixData?.secondTitle : null
              }
              ExpandedServiceSummaryComponent="true"
              primaryLocationNumber={selectedLocation?.locationNo}
              primaryLocationName={selectedLocation?.locationName}
              selectedDateMatrix={selectedDateMatrix}
              modalContentOther={modalServiceSummaryContent}
            />
            <div>
              <SummaryCard
                title={SERVICES}
                handleServicesCardClick={handleServicesCardClick}
                primaryLocationName={selectedLocation?.locationName}
                selectedDateMatrix={selectedDateMatrix}
              >
                {services_loading && (
                  <div className="data-row">
                    <CircularProgress color="primary" />
                  </div>
                )}
                {!services_loading && (
                  <>
                    <div style={{ paddingTop: "3px" }} className="data-row">
                      <SummaryDataItem
                        itemAmount={
                          `$ ` +
                          Math.round(balloon?.totalSalesAmount).toLocaleString(
                            "en"
                          )
                        }
                        itemTitle="Balloon Sales $"
                      />
                      <SummaryDataItem
                        itemAmount={Math.round(
                          balloon?.totalSalesUnits
                        ).toLocaleString("en")}
                        itemTitle="Balloon Sales U"
                      />
                    </div>
                    <div className="data-row">
                      <SummaryDataItem
                        itemAmount={
                          `$ ` +
                          Math.round(
                            earPiercing?.totalSalesAmount
                          ).toLocaleString("en")
                        }
                        itemTitle="Ear Piercing Sales $"
                      />
                      <SummaryDataItem
                        itemAmount={Math.round(
                          earPiercing?.serviceUnits
                        ).toLocaleString("en")}
                        itemTitle="Piercings"
                      />
                    </div>
                  </>
                )}
              </SummaryCard>
            </div>
          </div>
          <div className="OmniChannelCard-container">
            <Modal
              title={`${OMNI_CHANNEL} Summary`}
              titleInModal={
                omniChannelExpandedTitle ? salesMatrixData?.secondTitle : null
              }
              ExpandedOmniChannelComponent="true"
              primaryLocationNumber={selectedLocation?.locationNo}
              primaryLocationName={selectedLocation?.locationName}
              selectedDateMatrix={selectedDateMatrix}
              modalContentOther={modalOmniChannelSummaryContent}
            />
            <div>
              <SummaryCard
                title={OMNI_CHANNEL}
                handleOmniChannelCardClick={handleOmniChannelCardClick}
                primaryLocationName={selectedLocation?.locationName}
                selectedDateMatrix={selectedDateMatrix}
              >
                {omniChannelLandingLoading && (
                  <div className="data-row">
                    <CircularProgress color="primary" />
                  </div>
                )}
                {!omniChannelLandingLoading && (
                  <>
                    <div style={{ paddingTop: "3px" }} className="data-row">
                      <SummaryDataItem
                        itemAmount={Math.round(
                          bopis?.fulfilledOrdersCnt ?? 0
                        ).toLocaleString("en")}
                        itemTitle="BOPIS Orders Fulfilled"
                      />
                      <SummaryDataItem
                        itemAmount={
                          Math.round(bopis?.fillRate ?? 0).toLocaleString(
                            "en"
                          ) + `%`
                        }
                        itemTitle="BOPIS Fill Rate"
                      />
                    </div>
                    <div className="data-row">
                      <SummaryDataItem
                        itemAmount={Math.round(
                          sfs?.fulfilledOrdersCnt ?? 0
                        ).toLocaleString("en")}
                        itemTitle="SFS Orders Fulfilled"
                      />
                      <SummaryDataItem
                        itemAmount={
                          Math.round(sfs?.fillRate ?? 0).toLocaleString("en") +
                          `%`
                        }
                        itemTitle="SFS Fill Rate"
                      />
                    </div>
                  </>
                )}
              </SummaryCard>
            </div>
          </div>
          <div className="LaborCard-container">
            <Modal
              title={" Labor Summary"}
              titleInModal={labor?.secondTitle}
              ExpandedLaborSummaryComponent="true"
              primaryLocationNumber={selectedLocation?.locationNo}
              primaryLocationName={selectedLocation?.locationName}
              selectedDateMatrix={laborDateMatrix}
              modalContentOther={modalLaborSummaryContent}
            />
            <div>
              <SummaryCard
                title={LABOR}
                handleLaborCardClick={handleLaborCardClick}
              >
                {laborLoading && (
                  <div className="data-row">
                    <CircularProgress color="primary" />
                  </div>
                )}
                {!laborLoading && (
                  <>
                    <div className="data-row">
                      <SummaryDataItem
                        itemAmount={Math.round(
                          laborDetails?.primaryActualPayrollHrs
                        ).toLocaleString("en")}
                        itemTitle="Actual Payroll Hrs"
                      />
                      <SummaryDataItem
                        itemAmount={Math.round(
                          laborDetails?.primaryPayrollPlan
                        ).toLocaleString("en")}
                        itemTitle="Payroll Plan"
                      />
                    </div>
                    <div className="data-row">
                      <SummaryDataItem
                        itemAmount={toFixedVal(
                          laborDetails?.primaryEfficiency,
                          "%",
                          "end",
                          2
                        )}
                        itemTitle="Efficiency %"
                      />
                      <SummaryDataItem
                        itemAmount={toFixedVal(
                          laborDetails?.primaryOvertimePercentage,
                          "%",
                          "end",
                          2
                        )}
                        itemTitle="OT%"
                      />
                    </div>
                  </>
                )}
              </SummaryCard>
            </div>
          </div>
          {
            <div className="Inventory-container">
              <InventoryModal
                title={INVENTORY_CONTROL}
                titleInModal={salesMatrixData?.secondTitle}
                ExpandedInventorySummaryComponent="true"
                primaryLocationNumber={selectedLocation?.locationNo}
                primaryLocationName={selectedLocation?.locationName}
                selectedDateMatrix={selectedDateMatrix}
                modalContentOther={modalInventorySummaryContent}
              />
              <div>
                <SummaryCard
                  title="Inventory Control"
                  handleInventoryControlCardClick={
                    handleInventoryControlCardClick
                  }
                  primaryLocationName={selectedLocation?.locationName}
                  selectedDateMatrix={selectedDateMatrix}
                >
                  {inventoryloading && (
                    <div className="data-row">
                      <CircularProgress color="primary" />
                    </div>
                  )}
                  {!inventoryloading && (
                    <>
                      <div className="data-row">
                        <SummaryDataItem
                          itemAmount={Math.round(
                            inventoryControl?.replace?.unitQuantity ?? 0
                          ).toLocaleString("en")}
                          itemTitle="Replace"
                        />
                        <SummaryDataItem
                          itemAmount={Math.round(
                            inventoryControl?.damages?.unitQuantity ?? 0
                          ).toLocaleString("en")}
                          itemTitle="Damages"
                        />
                      </div>
                      <div className="data-row">
                        <SummaryDataItem
                          itemAmount={Math.round(
                            inventoryControl?.markedOutOfStock?.unitQuantity ??
                            0
                          ).toLocaleString("en")}
                          itemTitle="MOS"
                        />
                        <SummaryDataItem
                          itemAmount={Math.round(
                            inventoryControl?.knownTheft?.unitQuantity ?? 0
                          ).toLocaleString("en")}
                          itemTitle="Known Theft"
                        />
                      </div>
                      <div className="data-row">
                        <SummaryDataItem
                          itemAmount={Math.round(
                            inventoryControl?.shipment?.unitQuantity ?? 0
                          ).toLocaleString("en")}
                          itemTitle="Shipment"
                        />
                        <SummaryDataItem
                          itemAmount={Math.round(
                            inventoryControl?.candy?.unitQuantity ?? 0
                          ).toLocaleString("en")}
                          itemTitle="Candy"
                        />
                      </div>
                    </>
                  )}
                </SummaryCard>
              </div>
            </div>
          }
        </div>
      </AppHeader>
    </>
  );
};

const mapStateToProps = (state) => ({
  employeeInfo: state.auth.employeeInfo,
});

export default connect(mapStateToProps, undefined)(SalesSummary);
