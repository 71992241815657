import React, { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import { OidcClient } from '@pingidentity-developers-experience/ping-oidc-client-sdk';
import { pingIdConfig } from '../../config/pingIdConfig';

function PingIdLogin() {
    const [oidcClient, setOidcClient] = useState(null);

    // Get query parameters from the URL
    const search = useLocation().search;
    const activeStore = new URLSearchParams(search).get("storeId");
    const name = new URLSearchParams(search).get("source");

    // Store query parameters in local storage
    if (activeStore || activeStore === "") {
        localStorage.setItem("source", "wow-crew");
        localStorage.setItem("storeId", activeStore);
    } else {
        localStorage.setItem("storeId", "");
        if (name) {
            localStorage.setItem("source", name);
        } else {
            localStorage.setItem("source", "");
        }
    }

    useEffect(() => {
        // Function to initialize the OIDC client
        const initializeClient = async () => {
            try {
                const client = await OidcClient.initializeFromOpenIdConfig(process.env.REACT_APP_PINGONE_AUTH_URL, pingIdConfig);
                setOidcClient(client);
            } catch (error) {
                console.error('Error initializing OIDC client:', error);
            }
        };

        // Function to fetch user details if a token is available
        const fetchUserDetails = async () => {
            if (await oidcClient.hasToken()) {
                try {
                    var tokenDetails = await oidcClient.getToken();
                    var userInfoDetails = await oidcClient.fetchUserInfo();
                    console.log(userInfoDetails);

                    // Prepare response object with token and user details
                    let tempResponse = {
                        "idToken": tokenDetails.access_token,
                        "accessToken": tokenDetails.access_token,
                        "tokenType": "Bearer",
                        "account": {
                            "idTokenClaims": {
                                "employeeId": userInfoDetails.employeeId,
                                "adupn": userInfoDetails.adupn,
                                "name": userInfoDetails.name,
                                "isCorpUser": userInfoDetails.isCorpUser ? userInfoDetails.isCorpUser : true
                            }
                        }
                    }

                    // Store response and employee ID in local storage
                    localStorage.setItem('response', JSON.stringify(tempResponse));
                    localStorage.setItem('employeeID', userInfoDetails.employeeId);

                    // Redirect to summary page
                    window.location.replace("/summary");
                } catch (error) {
                    console.error('Error fetching user details:', error);
                }
            } else {
                // If no token is available, start the authorization process
                oidcClient.authorize();
            }
        };

        // Initialize the OIDC client if not already initialized, otherwise fetch user details
        if (!oidcClient) {
            initializeClient();
        } else {
            fetchUserDetails();
        }

    }, [oidcClient]);

    return (
        <div />
    );
};

export default PingIdLogin;