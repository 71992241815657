import React from "react";
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import ErrorPage from "../ErrorMessage/ErrorPage";

// PrivateRoute component to handle protected routes
const PrivateRoute = ({ component: Component, ...rest }) => {
  // Check if user is authenticated by checking local storage
  const isAuthenticated = localStorage.getItem("response") ? true : false;
  // Get employee information from Redux store
  const employeeInfo = useSelector((state) => state.auth.employeeInfo) || {};
  // Get user search error status from Redux store
  const isSearchUserError =
    useSelector((state) => state.auth.isSearchUserError) || false;
  // Get user search loading status from Redux store
  const isSearchUserLoading =
    useSelector((state) => state.auth.isSearchUserLoading) || false;

  return (
    <Route
      {...rest}
      render={(props) => {
        // If user is not authenticated, redirect to login page
        if (!isAuthenticated) {
          return <Redirect to="/login" />;
          // If employee information is available, render the component
        } else if (employeeInfo.adUpn) {
          return <Component {...props} />;
          // If there is an error in searching user, render error page
        } else if (isSearchUserError) {
          return <ErrorPage />;
          // If user search is in progress, show loading indicator
        } else if (isSearchUserLoading) {
          return (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "50vh",
                flexDirection: "column"
              }}
            >
              <CircularProgress color="primary" size={30} />
              <h2 className="mt-10">Searching User</h2>
            </div>
          );
          // If none of the above conditions are met, return null
        } else {
          return null;
        }
      }}
    />
  );
};

export default PrivateRoute;
