import React, { useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import { CircularProgress } from "@material-ui/core";
import {
  GET_DEFAULT_LOCATION,
  GET_MERCH_DATA,
  GET_INVENTORY_CONTROL_DRIVER_TAB_DETAIL,
  GET_INVENTORY_CONTROL_DEPARTMENT_SUMMARY,
  GET_INVENTORY_CONTROL_SKU_SUMMARY,
} from "../../graphql/queries";
import SelectDropdown from "../SelectDropdown";
import TotalDataRow from "./TotalDataRow";
import {
  ALL_DEPARTMENTS,
  DATA_FETCH_ERROR,
  ONLOAN_INVALID_STOREID_ERROR,
  NO_CONNECTION_ERROR,
  ALL_ADJUSTMENTS,
  TOTAL_ADJUSTMENTS,
  IC_REPLACE,
  DAMAGES,
  SHORT_MARKED_OUT_OF_STOCK,
  KNOWN_THEFT,
  SHIPMENT,
  CANDY,
  ALL_REASON_CODE,
} from "../../common/constants";
import Typography from "@material-ui/core/Typography";
import "./styles.scss";
import { connect } from "react-redux";
import {
  getDate,
  toTitleCase,
  getStoreChecker,
  getICMerchAdj_CodeDropdownOptions,
  roundValue,
  toFixedVal,
  getReasonCodeOptions_Replace,
  getReasonCodeOptions_Damage,
  getReasonCodeOptions_MOS,
  getReasonCodeOptions_KT,
  getReasonCodeOptions_Shipment,
  getReasonCodeOptions_Candy,
  getDefaultReasonCodeOption,
} from "../../utils/utils";

import ErrorMessage from "../ErrorMessage";
import InventoryMerchDataRow from "./InventoryMerchDataRow";
import useNetwork from "../UseNetwork";

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    fontSize: "1.6rem",
    color: state.isSelected ? "#FFFFFF" : "#353535",
    padding: 7,
  }),
};

const InventoryMerchDetailTab = ({ employeeInfo = {}, dateMatrix }) => {
  const networkStatus = useNetwork();
  const [storeMerchs, setStoreMerchs] = React.useState([]);
  const [selectedMerch, setSelectedMerch] = React.useState({});
  // const [expandSkuData, setExpandSkuData] = React.useState(true);
  const [selectedLocation, setSelectedLocation] = React.useState(
    JSON.parse(sessionStorage.getItem("selectedLocation"))
  );
  const userEmail = employeeInfo?.adUpn;
  let selectedStoreId = localStorage.getItem("storeId");
  const activeStorechecker = getStoreChecker(selectedStoreId);
  // eslint-disable-next-line no-unused-vars
  const [adjCodeDropdownOptions, setAdjCodeDropdowOptions] = React.useState(
    getICMerchAdj_CodeDropdownOptions
  );
  const [selectedAdjCodeDropdownOption, setSelectedAdjCodeDropdownOption] =
    React.useState([adjCodeDropdownOptions[0]]);
  const [selectedAdjustmentCodeValue, setSelectedAdjustmentCodeValue] =
    React.useState("");

  // Reason code Dropdown options
  // const defaultReasonCodeOption = { label: 'All Reason Codes', value: "" };
  // const [replaceDropdownOptions, setReplaceDropdownOptions] =
  //   React.useState(getReasonCodeOptions_Replace);
  // const [damagesDropdownOptions, setDamagesDropdownOptions] =
  //   React.useState(  getReasonCodeOptions_Damage    );
  // const [mosDropdownOptions, setMosDropdownOptions] =
  //   React.useState(getReasonCodeOptions_MOS);
  // const [knownTheftDropdownOptions, setKnownTheftDropdownOptions] =
  //   React.useState(getReasonCodeOptions_KT);
  // const [shipmentDropdownOptions, setShipmentDropdownOptions] =
  //   React.useState(getReasonCodeOptions_Shipment);
  // const [candyDropdownOptions, setCandyDropdownOptions] =
  //   React.useState(getReasonCodeOptions_Candy);

  const [reasonCodeDropdownOptions, setreasonCodeDropdownOptions] =
    React.useState(getDefaultReasonCodeOption);
  const [
    selectedReasonCodeDropdownOption,
    setSelectedReasonCodeDropdownOption,
  ] = React.useState([reasonCodeDropdownOptions[0]]);
  const [selectedReasonCodeValue, setSelectedReasonCodeValue] =
    React.useState("");

  const fetchOptionsBasedOnAdjustmentCode = (selectedCode) => {
    const code = selectedCode.toLowerCase();
    if (code === IC_REPLACE.toLowerCase()) {
      return [...getDefaultReasonCodeOption, ...getReasonCodeOptions_Replace];
    } else if (code === DAMAGES.toLowerCase()) {
      return [...getDefaultReasonCodeOption, ...getReasonCodeOptions_Damage];
    } else if (code === SHORT_MARKED_OUT_OF_STOCK.toLowerCase()) {
      return [...getDefaultReasonCodeOption, ...getReasonCodeOptions_MOS];
    } else if (code === KNOWN_THEFT.toLowerCase()) {
      return [...getDefaultReasonCodeOption, ...getReasonCodeOptions_KT];
    } else if (code === SHIPMENT.toLowerCase()) {
      return [...getDefaultReasonCodeOption, ...getReasonCodeOptions_Shipment];
    } else if (code === CANDY.toLowerCase()) {
      return [...getDefaultReasonCodeOption, ...getReasonCodeOptions_Candy];
    } else {
      return getDefaultReasonCodeOption;
    }
  };
  const fetchOptionsBasedOnDepartment = (code) => {
    if (code === ALL_DEPARTMENTS) {
      return [{ label: ALL_ADJUSTMENTS, value: ALL_ADJUSTMENTS }];
    } else {
      return getICMerchAdj_CodeDropdownOptions;
    }
  };

  useEffect(() => {
    const adjOptions = fetchOptionsBasedOnDepartment(selectedMerch.label);
    setAdjCodeDropdowOptions(adjOptions);
  }, [selectedMerch.label]);

  useEffect(() => {
    const options = fetchOptionsBasedOnAdjustmentCode(
      selectedAdjustmentCodeValue
    );
    setreasonCodeDropdownOptions(options);
  }, [selectedAdjustmentCodeValue]);

  const [getDefaultLocation, { error: locationError = {} }] = useLazyQuery(
    GET_DEFAULT_LOCATION,
    {
      context: {
        headers: {
          Authorization: localStorage.getItem("jwtToken"),
        },
      },
      onCompleted: (data) => {
        let { salesSummaryV2 } = data;
        if (selectedLocation.locationName === "NA") {
          setSelectedLocation({
            locationNo: salesSummaryV2?.location?.primaryLocationNumber,
            locationName: salesSummaryV2?.location?.primaryLocationName,
          });
          sessionStorage.setItem(
            "selectedLocation",
            JSON.stringify({
              locationNo: salesSummaryV2?.location?.primaryLocationNumber,
              locationName: salesSummaryV2?.location?.primaryLocationName,
            })
          );
        }
      },
    }
  );

  useEffect(() => {
    if (userEmail && (activeStorechecker === true || selectedStoreId === "")) {
      getDefaultLocation({
        variables: { request: { email: userEmail, storeId: selectedStoreId } },
      });
    }
  }, [userEmail, getDefaultLocation, selectedStoreId, activeStorechecker]);

  const [
    getMerchSummary,
    {
      data: { departmentSummary: { departmentSalesReport = [] } = {} } = {},
      error = {},
      loading,
    },
  ] = useLazyQuery(GET_MERCH_DATA, {
    context: {
      headers: {
        Authorization: localStorage.getItem("jwtToken"),
      },
    },
  });

  useEffect(() => {
    if (departmentSalesReport && departmentSalesReport?.length > 0) {
      let newOptions = [{ label: ALL_DEPARTMENTS, value: ALL_DEPARTMENTS }];
      if (departmentSalesReport?.length > 0) {
        departmentSalesReport.forEach((obj) => {
          if (obj["department"]) {
            let option = {};
            option.label =
              obj["department"]["deptName"] === "HBA"
                ? obj["department"]["deptNumber"] +
                  ": " +
                  obj["department"]["deptName"]
                : obj["department"]["deptNumber"] +
                  ": " +
                  toTitleCase(obj["department"]["deptName"]);
            option.value = obj["department"]["deptNumber"];
            newOptions.push(option);
          }
        });
        newOptions = newOptions.sort((a, b) => a.value - b.value);
        setStoreMerchs(newOptions);
        setSelectedMerch(newOptions[0]);
        // setExpandSkuData(false);
      }
    }
  }, [departmentSalesReport]);

  //getMerchSummary used for department data- first picker
  useEffect(() => {
    if (
      userEmail &&
      selectedLocation &&
      selectedLocation?.locationName !== "NA" &&
      dateMatrix
    ) {
      getMerchSummary({
        variables: {
          request: {
            email: userEmail,
            location: {
              primaryLocationNumber: selectedLocation?.locationNo,
              primaryLocationName: selectedLocation?.locationName,
            },
            toDate: getDate("YESTERDAY", ""),
            matrixType: dateMatrix,
          },
        },
      });
    }
  }, [userEmail, selectedLocation, dateMatrix, getMerchSummary]);

  const handleOnMerchChange = (option) => {
    if (![ALL_DEPARTMENTS].includes(option.label)) {
    }

    setSelectedMerch(option);
    setSelectedAdjCodeDropdownOption(adjCodeDropdownOptions[0]);
    setSelectedAdjustmentCodeValue("");
  };

  console.log(
    "check selectedMerch ,selectedAdjustmentCodeValue ",
    selectedMerch,
    selectedAdjustmentCodeValue
  );

  const handleOnAdjustmentDropdownChange = (option) => {
    let filteredAdjustmentDropdownData = [];

    if (![ALL_ADJUSTMENTS].includes(option.label)) {
      let tempData = [];
      tempData = adjCodeDropdownOptions.filter(
        (obj) => obj.value === option.value
      );
      if (tempData.length > 0) {
        filteredAdjustmentDropdownData = tempData;
        setSelectedAdjustmentCodeValue(filteredAdjustmentDropdownData[0].value);
      }
    } else {
      setSelectedAdjustmentCodeValue("");
    }
    setSelectedAdjCodeDropdownOption(option);
    setSelectedReasonCodeDropdownOption(reasonCodeDropdownOptions[0]);
    setSelectedReasonCodeValue("");
  };

  const handleOnReasonCodeChange = (option) => {
    let filteredReasonCodeDropdownData = [];

    if (![ALL_REASON_CODE].includes(option.label)) {
      let tempData = [];
      tempData = reasonCodeDropdownOptions.filter(
        (obj) => obj.value === option.value
      );
      if (tempData.length > 0) {
        filteredReasonCodeDropdownData = tempData;
        setSelectedReasonCodeValue(filteredReasonCodeDropdownData[0].value);
      }
    } else {
      setSelectedReasonCodeValue("");
    }
    setSelectedReasonCodeDropdownOption(option);
  };

  const [
    getInventoryControlDriversTabDetails, //All selected- Total -Same from driver screen
    {
      data: { inventoryControl: { invControlDrivers } = {} } = {},
      driverloading,
      // eslint-disable-next-line no-unused-vars
      drivererror,
    },
  ] = useLazyQuery(GET_INVENTORY_CONTROL_DRIVER_TAB_DETAIL, {
    context: {
      headers: {
        Authorization: localStorage.getItem("jwtToken"),
      },
    },
  });

  useEffect(() => {
    if (
      userEmail &&
      dateMatrix &&
      selectedLocation &&
      selectedLocation?.locationName !== "NA"
    ) {
      getInventoryControlDriversTabDetails({
        variables: {
          request: {
            email: userEmail,
            location: {
              primaryLocationNumber: selectedLocation?.locationNo,
              primaryLocationName: selectedLocation?.locationName,
            },
            toDate: getDate("YESTERDAY", ""),
            matrixType: dateMatrix,
          },
        },
      });
    }
  }, [
    userEmail,
    dateMatrix,
    getInventoryControlDriversTabDetails,
    selectedLocation,
  ]);

  const {
    total = {
      unitQuantity: 0,
      itemCount: 0,
      adjustmentsAmount: 0,
    },
  } = invControlDrivers || {};

  const [
    getInventoryControlDepartmentReport,
    {
      data: {
        inventoryControlDepartmentSummary: {
          inventoryControlDepartmentTotalReport,
        } = {},
      } = {},

      deptloading,
      // eslint-disable-next-line no-unused-vars
      depterror = {},
    },
  ] = useLazyQuery(GET_INVENTORY_CONTROL_DEPARTMENT_SUMMARY, {
    context: {
      headers: {
        Authorization: localStorage.getItem("jwtToken"),
      },
    },
  });

  useEffect(() => {
    if (
      userEmail &&
      dateMatrix &&
      selectedLocation &&
      selectedLocation?.locationName !== "NA" &&
      selectedMerch
    ) {
      getInventoryControlDepartmentReport({
        variables: {
          request:
            selectedMerch.label !== ALL_DEPARTMENTS &&
            selectedAdjustmentCodeValue === ""
              ? {
                  email: userEmail,
                  location: {
                    primaryLocationNumber: selectedLocation?.locationNo,
                    primaryLocationName: selectedLocation?.locationName,
                  },
                  toDate: getDate("YESTERDAY", ""),
                  matrixType: dateMatrix,
                  adjustmentCategory: TOTAL_ADJUSTMENTS,
                  departmentNumber: selectedMerch.value,
                }
              : selectedMerch.label !== ALL_DEPARTMENTS &&
                selectedAdjustmentCodeValue !== ""
              ? {
                  email: userEmail,
                  location: {
                    primaryLocationNumber: selectedLocation?.locationNo,
                    primaryLocationName: selectedLocation?.locationName,
                  },
                  toDate: getDate("YESTERDAY", ""),
                  matrixType: dateMatrix,
                  adjustmentCategory: selectedAdjustmentCodeValue,
                  departmentNumber: selectedMerch.value,
                }
              : {
                  email: userEmail,
                  location: {
                    primaryLocationNumber: selectedLocation?.locationNo,
                    primaryLocationName: selectedLocation?.locationName,
                  },
                  toDate: getDate("YESTERDAY", ""),
                  matrixType: dateMatrix,
                  adjustmentCategory: selectedAdjustmentCodeValue,
                  departmentNumber: selectedMerch.value,
                  adjustmentDescription: selectedReasonCodeValue,
                },
        },
      });
    }
  }, [
    userEmail,
    dateMatrix,
    getInventoryControlDepartmentReport,
    selectedLocation,
    selectedMerch,
    selectedAdjustmentCodeValue,
    selectedReasonCodeValue,
  ]);

  //getting sku level data for all except total
  const [
    getInventoryControlSkuData,
    {
      data: {
        inventoryControlSkuSummary: { inventoryControlSkuSalesReport } = {},
      } = {},
      skuError,
      skuLoading,
    },
  ] = useLazyQuery(GET_INVENTORY_CONTROL_SKU_SUMMARY, {
    context: {
      headers: {
        Authorization: localStorage.getItem("jwtToken"),
      },
    },
  });

  useEffect(() => {
    if (
      userEmail &&
      selectedLocation &&
      selectedLocation?.locationName !== "NA" &&
      dateMatrix
    ) {
      getInventoryControlSkuData({
        variables: {
          request:
            selectedMerch.label !== ALL_DEPARTMENTS &&
            selectedAdjustmentCodeValue !== ""
              ? {
                  email: userEmail,
                  location: {
                    primaryLocationNumber: selectedLocation?.locationNo,
                    primaryLocationName: selectedLocation?.locationName,
                  },
                  toDate: getDate("YESTERDAY", ""),
                  matrixType: dateMatrix,
                  departmentNumber: selectedMerch.value,
                  adjustmentCategory: selectedAdjustmentCodeValue,
                  adjustmentDescription: selectedReasonCodeValue,
                }
              : selectedMerch.value !== ALL_DEPARTMENTS &&
                selectedAdjustmentCodeValue !== ""
              ? {
                  email: userEmail,
                  location: {
                    primaryLocationNumber: selectedLocation?.locationNo,
                    primaryLocationName: selectedLocation?.locationName,
                  },
                  toDate: getDate("YESTERDAY", ""),
                  matrixType: dateMatrix,
                  adjustmentCategory: selectedAdjustmentCodeValue,
                  departmentNumber: selectedMerch.value,
                }
              : selectedMerch.label !== ALL_DEPARTMENTS &&
                selectedAdjustmentCodeValue === ""
              ? {
                  email: userEmail,
                  location: {
                    primaryLocationNumber: selectedLocation?.locationNo,
                    primaryLocationName: selectedLocation?.locationName,
                  },
                  toDate: getDate("YESTERDAY", ""),
                  matrixType: dateMatrix,
                  adjustmentCategory: TOTAL_ADJUSTMENTS,
                  departmentNumber: selectedMerch.value,
                }
              : {
                  email: userEmail,
                  location: {
                    primaryLocationNumber: selectedLocation?.locationNo,
                    primaryLocationName: selectedLocation?.locationName,
                  },
                  toDate: getDate("YESTERDAY", ""),
                  matrixType: dateMatrix,
                  adjustmentCategory: TOTAL_ADJUSTMENTS,
                },
        },
      });
    }
  }, [
    userEmail,
    selectedLocation,
    dateMatrix,
    getInventoryControlSkuData,
    selectedMerch,
    selectedAdjustmentCodeValue,
    selectedReasonCodeValue,
  ]);
  console.log(
    "selectedMerch, adj, reasonCode",
    selectedMerch.value,
    ",",
    selectedAdjustmentCodeValue,
    ",",
    selectedReasonCodeValue
  );
  console.log("inventoryControlSkuSalesReport", inventoryControlSkuSalesReport);

  return (
    <div className="ic-merch-detail-tab">
      {!networkStatus && <ErrorMessage message={NO_CONNECTION_ERROR} />}
      {(loading || deptloading || skuLoading) && (
        <div className="ic-merch-detail-loader">
          <CircularProgress color="primary" size={30} />
        </div>
      )}
      {!loading && (
        <>
          {error.message ? (
            <ErrorMessage
              statusCode={
                error?.networkError?.statusCode ||
                locationError?.networkError?.statusCode
              }
              message={DATA_FETCH_ERROR}
            />
          ) : error.mesage ? (
            <ErrorMessage
              statusCode={
                error?.networkError?.statusCode ||
                locationError?.networkError?.statusCode
              }
              message={error.message}
            />
          ) : //  : inventoryMerchError.message ? (
          //   <ErrorMessage
          //     statusCode={inventoryMerchError?.status}
          //     message={inventoryMerchError.message}
          //   />
          // )
          null}
          {activeStorechecker === false && selectedStoreId ? (
            <ErrorMessage message={ONLOAN_INVALID_STOREID_ERROR} />
          ) : null}
          {departmentSalesReport?.length === 0 ? (
            <div className="ic-merch-noData-card">
              <div className="no-data-row">
                <Typography component="h1" variant="h1" className="label">
                  There is no data available for this timeframe. Please go back
                  and try a new selection{" "}
                </Typography>
              </div>
            </div>
          ) : (
            <>
              <div className="dropdown-container">
                <div className="dropdown-column">
                  <SelectDropdown
                    className="dropdown-row"
                    styles={customStyles}
                    onChange={handleOnMerchChange}
                    options={storeMerchs}
                    value={selectedMerch}
                    isSearchable={false}
                  />
                </div>

                <div className="dropdown-column class-level-dropdown">
                  <SelectDropdown
                    className="dropdown-row"
                    styles={customStyles}
                    onChange={handleOnAdjustmentDropdownChange}
                    options={adjCodeDropdownOptions}
                    value={selectedAdjCodeDropdownOption}
                    isSearchable={false}
                  />
                </div>

                {selectedMerch.label !== ALL_DEPARTMENTS &&
                  selectedAdjustmentCodeValue !== "" && (
                    <div className="dropdown-column class-level-dropdown">
                      <SelectDropdown
                        className="dropdown-row"
                        styles={customStyles}
                        onChange={handleOnReasonCodeChange}
                        options={reasonCodeDropdownOptions}
                        value={selectedReasonCodeDropdownOption}
                        isSearchable={false}
                      />
                    </div>
                  )}
              </div>

              <div className="total-table-container">
                <div className="top-border-row"> </div>

                {!driverloading &&
                selectedMerch.value === ALL_DEPARTMENTS &&
                selectedAdjustmentCodeValue === "" ? (
                  <TotalDataRow
                    title={"Store Total"}
                    unitQuantity={roundValue(total?.unitQuantity)}
                    itemCount={roundValue(total?.itemCount)}
                    adjustmentsAmount={toFixedVal(
                      total?.adjustmentsAmount,
                      "$",
                      "start",
                      2
                    )}
                  />
                ) : (
                  !deptloading && (
                    // selectedMerch.label !== ALL_DEPARTMENTS &&
                    // selectedAdjustmentCodeValue !== "" &&
                    <TotalDataRow
                      title={selectedMerch.label}
                      unitQuantity={roundValue(
                        inventoryControlDepartmentTotalReport?.unitQuantity ?? 0
                      )}
                      itemCount={roundValue(
                        inventoryControlDepartmentTotalReport?.itemCount ?? 0
                      )}
                      adjustmentsAmount={toFixedVal(
                        inventoryControlDepartmentTotalReport?.adjustmentAmount ??
                          0,
                        "$",
                        "start",
                        0
                      )}
                    />
                  )
                )}

                <div className="longsku-row-container">
                  {!skuError && inventoryControlSkuSalesReport?.length !== 0 ? (
                    !skuLoading &&
                    inventoryControlSkuSalesReport?.map((data, index) => (
                      <InventoryMerchDataRow
                        key={index}
                        title={data.itemName}
                        skuNumber={data.itemNumber ?? 0}
                        unitQuantity={data.unitQuantity ?? 0}
                        amount={
                          toFixedVal(
                            data.adjustmentAmount ?? 0,
                            "$",
                            "start",
                            0
                          ) ?? 0
                        }
                      />
                    ))
                  ) : (
                    <div className="noData-card">
                      <div className="no-data-row">
                        <Typography
                          component="h1"
                          variant="h1"
                          className="label"
                        >
                          There is no data available for this timeframe. Please
                          go back and try a new selection{" "}
                        </Typography>
                      </div>
                    </div>
                  )}
                  {/* !skuLoading && (
                <InventoryMerchDataRow />) */}
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  employeeInfo: state.auth.employeeInfo,
  dateMatrix: state.salesSummary.currentDateMatrix.value,
});

export default connect(mapStateToProps, undefined)(InventoryMerchDetailTab);
