import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Route, Switch, BrowserRouter } from "react-router-dom";
import PrivateRoute from "../PrivateRoute/PrivateRoute";
import SalesSummary from "../SalesSummary";
import { privacyPolicy, termsAndCondition } from "../PrivacyTermsAndCondition";
import { setAuthToken } from "../../utils";
import { setLoginUserInfo } from "../../actions/actions";
import { searchUser } from "../../actions/userProfileActions";
import SummaryDetails from "../SummaryDetails";
import LogOut from "../LogOut/LogOut";
import LaborDetails from "../LaborDetails";
import ServicesDetails from "../ServicesDetails/ServicesDetails";
import InventoryControlDetails from "../InventoryControlDetails";
import OmniChannelDetails from "../OmniChannelDetails/OmniChannelDetails";
import PingIdLogin from "../PingIdLogin/PingIdLogin";

const PageNavigation = ({ setLoginUserInfo, searchUser }) => {
  // Retrieve the ping response from local storage
  let pingResponse = localStorage.getItem("response");

  useEffect(() => {
    // If ping response exists, parse it and set authentication token
    if (pingResponse) {
      let tmpPingResponse = JSON.parse(pingResponse);
      setAuthToken(tmpPingResponse['accessToken'], tmpPingResponse['account']['idTokenClaims']['employeeId']);
      // Search user and set login user info
      searchUser({ username: tmpPingResponse['account']['idTokenClaims']['employeeId'] });
      setLoginUserInfo(tmpPingResponse);
      // Store corporate user status in session storage
      sessionStorage.setItem(
        "isCorpUser",
        tmpPingResponse['account']['idTokenClaims']['isCorpUser']
      );
    }
  }, [setLoginUserInfo, searchUser, pingResponse]);

  return (
    <div>
      <BrowserRouter>
        {/* Define routes for different components */}
        <Route exact path="/login" component={PingIdLogin} />
        <Route exact path="/" component={PingIdLogin} />
        <Route exact path="/privacy" component={privacyPolicy} />
        <Route
          exact
          path="/terms-and-conditions"
          component={termsAndCondition}
        />
        <Route exact path="/logout" component={LogOut} />
        <Switch>
          {/* Define private routes for authenticated users */}
          <PrivateRoute exact path="/summary" component={SalesSummary} />
          <PrivateRoute
            exact
            path="/sales-details/:tabId"
            component={SummaryDetails}
          />
          <PrivateRoute
            exact
            path="/services-details/:tabId"
            component={ServicesDetails}
          />
          <PrivateRoute
            exact
            path="/labor-details/:tabId"
            component={LaborDetails}
          />
          <PrivateRoute
            exact
            path="/inventory-details/:tabId"
            component={InventoryControlDetails}
          />
          <PrivateRoute
            exact
            path="/omnichannel-details/:tabId"
            component={OmniChannelDetails}
          />
        </Switch>
      </BrowserRouter>
    </div>
  );
};

// Map dispatch actions to component props
const mapDispatchToProps = (dispatch) => ({
  setLoginUserInfo: (data) => dispatch(setLoginUserInfo(data)),
  searchUser: (userData) => dispatch(searchUser(userData)),
});

// Connect component to Redux store
export default connect(undefined, mapDispatchToProps)(PageNavigation);
